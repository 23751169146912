import { Observer } from "mobx-react-lite"
import * as React from "react"
import ContactForm from "../components/ContactForm/ContactForm"
import DefaultPageHeader from "../components/DefaultPageHeader/DefaultPageHeader"
import PageTemplate from "../components/PageTemplate/PageTemplate"
import TricolorMorphDef from "../components/TricolorMorphDef/TricolorMorphDef"
import useSiteMetadata from "../hooks/useSiteMetadata.hook"
import { PageComponent } from "../types/gatsby.types"
import { ColorPalette } from "../utils/colors.utils"
import './contact.scss'


const PageContact: PageComponent = props => {

  const { keywords } = useSiteMetadata();
  
  return <Observer children={() => (
    <PageTemplate
      {...props} // always include
      className="PageContact"
      title="Contact"
      description="Contact AxonDivision."
      keywords={['contact', ...keywords]}
      backgroundColor={ColorPalette.ivory}
      foregroundColor={ColorPalette.dark}
    >
      <TricolorMorphDef
        mobile={{
          R: 'M241.682 -738H253.682L253.682 289.325L247.682 293L241.682 289.325L241.682 -738Z',
          G: 'M-642 813.391L-648 802.998L241.689 289.336L247.689 292.992L247.689 299.728L-642 813.391Z',
          B: 'M247.681 299.736L247.682 293L253.681 289.343L1143.37 803.006L1137.37 813.398L247.681 299.736Z',
        }}
        desktop={{
          R: "M862 -704H874L874 323.325L868 327L862 323.325L862 -704Z",
          G: "M-21.6816 847.391L-27.6816 836.998L862.008 323.336L868.008 326.992L868.008 333.728L-21.6816 847.391Z",
          B: "M867.999 333.736L868 327L873.999 323.343L1763.69 837.006L1757.69 847.398L867.999 333.736Z",
        }}
      />
      <DefaultPageHeader
        id="ContactPageHeader"
        title="Contact"
      >
      </DefaultPageHeader>

      <ContactForm />

    </PageTemplate>
    
  )} />
}

export default PageContact
